<template>
  <div class="brief">
    <h1 class="brief-section title">公司简介</h1>

    <div class="brief-section content">
      <h4>
        太原道恩威尔科技有限公司创立于2019年7月，是一家以开发软硬件、小程序、网站、游戏为主的创新型科技公司。道恩威尔一贯坚持“创新合作、开放包容”的发展理念。公司自创立以来，积极调研市场，寻找合作伙伴，
        与太原、山东多家科技公司与物联网公司联动发展，建立合作关系，探索新技术的开发与应用，目前已形成多方位、多角度、多行业的“互联网+”生态系统。道恩威尔还在太原、新疆、青海、山东等地开拓市场，积累资源，
        产品服务涉及互联网、教育、物联网三大领域，目前主要项目集中于“四地三领域”协同发展，依托当地企业和资源实现合作伙伴共赢的局面。
      </h4>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.brief {
  padding-bottom: 2vh;
  background-color: rgb(250, 250, 250);
}
.brief-section.title {
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(#fff, rgb(250,250,250)); */
}
.brief-section.content {
  padding: 2vw 15vw;
}
.brief-section.content h4 {
  text-indent: 2.2em;
  letter-spacing: 1.1pt;
  text-align: justify;
  font-size: 1em;
  line-height: 1.5em;
}
/* Iphone5 */
@media screen and (max-width: 450px) {
  .brief-section.title {
    min-height: 8vh;
    font-size: 1.7em;
  }
  .brief-section.content h4 {
    text-indent: 1.8em;
    letter-spacing: 1pt;
    text-align: justify;
    font-size: 0.9em;
    line-height: 1.5em;
  }
}
</style>