<template>
  <div class="product">
    <div class="product-section title">
      <h1>业务产品</h1>
    </div>
    <div class="product-section content">
      <div class="product-content">
        <h4 class="product-content title">智慧灯杆</h4>
        <div id="CityLight">
          <p class="product-content discription">
            道恩威尔智能灯杆是一款集成智慧屏的商用路灯，其具有集成化，定制化，可交互等优点，可被广泛运用于社区，景区，步行街等场景。
          </p>
        </div>
      </div>
      <div class="product-content">
        <h4 class="product-content title">智慧社区</h4>
        <div id="Community">
          <p class="product-content discription">
            道恩威尔智慧社区是一套社区管理智慧化数字化解决方案，其通过数字硬件、软件帮助社区完成管理组织，信息流通，物业管理等操作。
          </p>
        </div>
      </div>
      <div class="product-content">
        <h4 class="product-content title">便捷支付</h4>
        <div id="DawnwarePayment">
          <p class="product-content discription">
            道恩威尔便捷支付是一套在线支付集成平台，其帮助商家用户可以更快捷方便的接入互联网支付系统，并且获得数据分析的能力。
          </p>
        </div>
      </div>
      <div class="product-content">
        <h4 class="product-content title">Dawn Studios</h4>
        <div id="DawnStudios">
          <p class="product-content discription">
            Dawn
            Studios是道恩威尔内部的一个数字化媒体工作室，其通过交互艺术设计为客户带来了数据可视化以及交互艺术的体验。在2022年获得Steam
            Patner认证。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.product-section {
  background-color: rgb(250, 250, 250);
}
.product-section.title {
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(#fff, rgb(250,250,250)); */
}
.product-section.content {
  display: flex;
  flex-flow: row wrap;
}
.product-section > .product-content {
  width: 25vw;
  min-height: 20em;
}
.product-content.title {
  letter-spacing: 1pt;
}
.product-content.discription {
  padding: 1em 4em;
  font-size: 0.95em;
  letter-spacing: 1pt;
  text-align: start;
  text-indent: 2em;
}
/* middle屏幕设备 */
@media screen and (max-width: 1200px) {
  .product-section > .product-content {
    width: 50vw;
  }
}

/* Iphone5 */
@media screen and (max-width: 450px) {
  .product-section.title h1{
    min-height: 10vh;
    font-size: 1.7em;
  }
  .product-section > .product-content {
    width: 100vw;
    min-height: 10em;
  }
  .product-content.discription {
    font-size: 0.9em;
  }
}
</style>
