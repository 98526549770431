<template>
  <div class="section header-section">
    <div class="section-content left">
      <img src="@/assets/logo-trans.png" />
    </div>
    <div class="section-content right">
      <h1 id="company-name">道恩威尔</h1>
      <h1>
        以科技服务生活<br />
        促进可持续发展的科技生态链
      </h1>
    </div>
    <img
      class="trun-down"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAYCAYAAAC1Ft6mAAAAAXNSR0IArs4c6QAAAZVJREFUWEfVl7suRVEQhr9fJdFR0HgHKg8hQZQKjUZ0WofKpVW5NBqFUpB4CBXvoOEBJCojS5ZknWMvZ5+z15zLKvdlMt+a+f/MyMymgWPgCziU9MYYHTObAw6ACaAlMzsDdiLDK7Aq6XkcmMxsEbgH5mO+5wHoAthOAD6ATUm3owxlZuvANTCV5HkZgGaAR2ApeWGhjJJCK47cMbNWkAegJLknYPnngZlNAlfARkf2N8CWpM9RoKqTZ0oYwHLka8M2iyj+u26d1AYUq1XVm8EsViS9DKNSZrYAPCTiD2lUav0PUITqdI9sAG/AjPizblwJFKGCv1eVeF/SiTdIzGEPOKoQf1YCWaBhmkUd8ecu9F+g358GaRZ1xd8IaFBm0Yv4GwN5m0Wv4i8C1MUs+p4sSrZ0LQ113kYT0aaxSsVJY/YFVMIsmoq/WMtVVKvnyaKE+N2AejWLUuJ3BaprFiXF7w5UY7IIn7ivJ41MIXdLmUq0GZzXAukC9M9k4T61uwFlzMJ9r3IFilCzwG7st1NJ756rxzdBcPxeOJr8LAAAAABJRU5ErkJggg=="
      alt=""
      @click="toNextSection"
    />
  </div>
</template>

<script>
export default {
  mounted() {},
  methods: {
    toNextSection() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top +=
            10;
        if (top >= window.innerHeight) {
          clearInterval(timeTop);
        }
      }, 2);
    },
  },
};
</script>

<style scoped>
#company-name {
  font-size: 4em;
  color: #003091;
}
.header-section {
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.section-content {
  margin: 0 3vw;
}
.section-content.left {
}
.section-content.left img {
  width: 28vw;
  max-width: 300pt;
  min-width: 100pt;
}
.section-content.right {
  max-width: 45em;
}
.section-content.right h1 {
  /* color: #00b8e6; */
  font-size: 3.2em;
  text-align: left;
  font-weight: 600;
  letter-spacing: 2pt;
  margin: 20pt 0;
  font-family: Gilroy-regular, -apple-system, BlinkMacSystemFont, Segoe UI,
    PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
.trun-down {
  position: absolute;
  bottom: 1.5vh;
  width: 2.5vw;
  min-width: 20pt;
  transform: translate(-50%, -50%);
}

/* middle屏幕设备 */
@media screen and (max-width: 1200px) {
  #company-name {
    font-size: 3.3em;
  }
  .section-content.left img {
    width: 35vw;
    max-width: 300pt;
    min-width: 100pt;
  }
  .section-content {
    margin: 0;
  }
  .section-content.right h1 {
    font-size: 3em;
    text-align: center;
    font-weight: 600;
    letter-spacing: 2pt;
    margin: 10pt 0;
    line-height: 150%;
  }
  .header-section {
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
}

/* Iphone5 */
@media screen and (max-width: 450px) {
  #company-name {
    font-size: 2em;
  }
  .section-content {
    margin: 0;
  }
  .section-content.left {
    margin-top: 5vh;
  }
  .section-content.right h1 {
    font-size: 1em;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1.5pt;
    line-height: 160%;
  }
}
</style>