<template>
  <div class="section values-section">
    <h1 class="values-section title">企业文化</h1>
    <div class="section-container">
      <div class="section-content value">
        <div class="value-content left values">
          <h2>价值观</h2>
        </div>
        <div class="value-content right values">
          <div id="trust">
            <h3>相信</h3>
            <p>对自己，对团队，对所做的事永远信任</p>
          </div>
          <div id="growth">
            <h3>突破</h3>
            <p>以成员的自驱力带来双方全方位地成长</p>
          </div>
          <div id="open">
            <h3>开放</h3>
            <p>对新鲜事物保持好奇，对用户包容开放</p>
          </div>
        </div>
      </div>
      <div class="section-content value">
        <div class="value-content left mission">
          <h2>使命</h2>
        </div>
        <div class="value-content right mission">
          <h3>以科技服务生活 促进可持续发展的科技生态链</h3>
        </div>
      </div>
      <div class="section-content value">
        <div class="value-content left vision">
          <h2>愿景</h2>
        </div>
        <div class="value-content right vision">
          <h3>用科技为生活带来便利的同时充满乐趣</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.value-content > h2 {
  /* color: #00b8e6; */
  font-size: 2.5em;
}
.values-section.title {
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(#fff, rgb(250,250,250)); */
}
.section-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.section.values-section {
  display: flex;
  flex-flow: column nowrap;
  background-color: rgb(250,250,250);
}
.section-content {
  /* width: 100vw; */
  min-height: 30vh;
}
.value-content {
  margin: 0 1.5vw;
}
.value-content.right.values p {
  width: 100%;
  text-align: justify;
  font-weight: 500;
}
.value-content.right {
  line-height: 175%;
  letter-spacing: 1.3pt;
  text-align: center;
}
.value-content.right h3 {
  letter-spacing: 1.2pt;
}
.section-content.value {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: stretch;
}

/* middle屏幕设备 */
@media screen and (max-width: 1750px) {
  .section-content.value {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    align-content: center;
    max-width: 30vw;
  }

  .value-content.right {
    position: relative;
    top: 20%;
  }
}

@media screen and (max-width: 1135px) {
  .section-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .section-content.value {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    max-width: 100vw;
  }
  .value-content {
    margin: 16pt 0;
  }
}

/* Iphone5 */
@media screen and (max-width: 450px) {
  .value-content > h2 {
    font-size: 1.5em;
  }
  .section-content.value {
    min-height: 20vh;
  }
  .section-container {
    padding: 10pt;
  }
  .value-content {
    margin: 10pt 0;
  }
  .values-section.title {
    min-height: 8vh;
    font-size: 1.7em;
    background-image: none;
  }

  .value-content.right {
    font-size: 1em;
    line-height: 150%;
    letter-spacing: 0;
  }
  .value-content.right.values p {
    font-size: 0.9em;
  }
  .value-content.right h3 {
    font-size: 1em;
  }
}
</style>