<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/>  -->
    <Header />
    <Brief />
    <Values />
    <Products />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/homeViews/Header.vue";
import Values from "@/components/homeViews/Values.vue";
import Brief from "@/components/homeViews/Brief.vue";
import Products from "@/components/homeViews/Products.vue";

export default {
  name: "Home",
  components: {
    Header,
    Values,
    Brief,
    Products,
  },
};
</script>

<style>
.section {
  max-height: 1440px;
  display: block;
}
</style>